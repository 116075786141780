import { Button, Card, Col, Form, Image, Input, message, Row, Spin } from "antd";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { resetPassword } from "../../services/auth";
import './style.css';
import backgroud from "../../assets/Softcodeit_Logo-02.png";

export interface ForgotPasswordForm{
  email: string;
}

export const ForgotPassword: Function = () => {
  let [loading, setLoading] = useState(false);
  let { push } = useHistory();
  if (loading) {
    return <Spin size="large" className="spin" />;
  }
    return (
      <Row align="middle" className="full-height">
        <Col span={24}>
          <Card className="login-logo">
            <Image src={backgroud} preview={false}/>
          </Card>
          <Card  bordered={false}>
            <Form
              name="basic"
              className="forgotPWForm"
              layout={"vertical"}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={(data: ForgotPasswordForm) => {
                setLoading(true);
                resetPassword(data).then((respnse: any) => {
                  setLoading(false);
                  if (respnse.error) {
                    message.error(respnse.error);
                    return;
                  } else if(respnse.message){
                    message.success(respnse.message);
                  }
                }).catch(() => {
                  setLoading(false);
                  message.error('Failed to reset password, please try again ...!');
                });
              }}
              onFinishFailed={() => {
                message.error('Please type your softcodeit email!');
              }}
            >
              <Form.Item
                label="Email"
                name="email"
                hasFeedback
                rules={[
                  { required: true, type: 'email', message: "Please input your registered email!" },
                ]}
              >
                <Input />
              </Form.Item>
              
              <Row>
                <Col span={8}>
                  <Button type="default" onClick={() => {
                    push('/login');
                  }}>
                    Back
                  </Button>
                </Col>
                <Col span={14} offset={2}>
                  <Button type="primary" htmlType="submit">
                    Send Password Reset Link
                  </Button>
                </Col>
              </Row>
            </Form>
          </Card>
        </Col>
      </Row>
    );
}