import {
  Col,
  PageHeader,
  Row,
  Form,
  Input,
  Button,
  Select,
  message,
  DatePicker,
  Divider,
  Spin,
  Upload, Checkbox,
  UploadProps,
} from "antd";
import "./style.css";
import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { Designation, User } from "../../types/types";
import { emptyDesignation } from "../announcementForm/AnnouncementForm";
import { getDesignations } from "../../services/designation";
import { createEmployee, updateEmployee } from "../../services/auth";
import moment from "moment";
import {CheckboxChangeEvent} from "antd/es/checkbox";

export interface EventFormProps {
  onDone: Function;
  data: User; 
}
export const EmployeeForm = ({ onDone, data }: EventFormProps) => {
  let [designations, setDesignations] = useState([emptyDesignation]);
  let [uploadImages, setUploadImages] = useState<any[]>([]);
  let [isLoading, setIsloading] = useState(false);
  let [optionCurrentAddress,setOptionCurrentAddress] = useState(true);
  useEffect(() => {
    getDesignations().then((designationList: Designation[]) => {
      setDesignations(designationList);
    });
    // check permanent address as current address
    setOptionCurrentAddress(data.details === undefined || data.details.current_address == null || data.details.address===data.details.current_address);

  }, []);
  const onChangeOptionCurrentAddress = (e:CheckboxChangeEvent)=>{
    setOptionCurrentAddress(e.target.checked);
  }
  
  if (isLoading) {
    return <Spin className="spin" size="large" />;
  }
  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            onBack={() => {
              onDone();
            }}
            title={(data.id && data.id > 0 ? (data.details && data.details.first_name && data.details.last_name ? data.details.first_name + " " + data.details.last_name  : 'Edit Employee') :"Add new Employee")}
            subTitle={(data.id && data.id > 0 ? (data.details && data.details.designation.name ? data.details.designation.name : "" ) : "Please fill out the information below")}
          />
        </Col>
      </Row>
      <Row>
        <Col span={22}>
          <Form
            name="basic"
            className="form-employee"
            layout={"vertical"}
            labelCol={{ span: 22 }}
            wrapperCol={{ span: 22 }}
            initialValues={{ remember: true }}
            onFinish={(value: any) => {
              setIsloading(true);
              if (data.id && data.id > 0) {
                updateEmployee({
                  image: uploadImages.length > 0 && uploadImages[0] && uploadImages[0].response ? uploadImages[0].response : 0,
                  first_name: value.firstName,
                  last_name: value.lastName,
                  designation_id: value.designation,
                  dob: value.dob.format("YYYY-MM-DD"),
                  mobile: value.mobile,
                  fixedline: value.fixedLine,
                  emg_name: value.emergencyName,
                  emg_phone: value.emergencyNo,
                  address: value.address,
                  gender:value.gender,
                  current_address: optionCurrentAddress?value.address:value.current_address
                }, data.id).then((response: any) => {
                  setIsloading(false);
                  if (response.error) {
                    message.error(response.error);
                    return;
                  }
                  message.success("Employee Saved!");
                  onDone();
                }).catch((e: any) => {
                  setIsloading(false);
                });
              } else {
                createEmployee({
                  image: uploadImages.length > 0 && uploadImages[0] && uploadImages[0].response ? uploadImages[0].response : 0,
                  first_name: value.firstName,
                  last_name: value.lastName,
                  email: value.email,
                  designation_id: value.designation,
                  dob: value.dob.format("YYYY-MM-DD"),
                  mobile: value.mobile,
                  fixedline: value.fixedLine,
                  emg_name: value.emergencyName,
                  emg_phone: value.emergencyNo,
                  address: value.address,
                  gender:value.gender,
                  current_address: optionCurrentAddress?value.address:value.current_address
                }).then((response: any) => {
                  setIsloading(false);
                  if (response.error) {
                    message.error(response.error);
                    return;
                  }
                  message.success("Employee Saved!");
                  onDone();
                }).catch((e: any) => {
                  setIsloading(false);
                });
              }
            }}
            onFinishFailed={() => { }}
          >
            <Row>
              <Col span={6}>
              <div className="profImg"> 
                    
                <Form.Item
                  name="dragger"
                  valuePropName="image"
                  getValueFromEvent={({ fileList }) => {
                    setUploadImages(fileList);
                  }}
                  noStyle
                  initialValue={data.details && data.details.profile_image ? data.details.profile_image : null}
                >
                  <Upload.Dragger
                    maxCount={1}
                    showUploadList={{showPreviewIcon: false}}
                    name="image"
                    listType="picture-card"
                    action={
                      process.env.REACT_APP_API_URL +
                      "/api/profile-image"
                    }

                    defaultFileList={data.details && data.details.profile_image?[
                      {
                        uid: '',
                        name: 'image',
                        status: 'done',
                        response: 'Server Error 500',
                        url: data.details.profile_image

                      }
                    ]:[]}
                    
                  >
                    <p className="ant-upload-drag-icon">
                      <PlusOutlined />
                    </p>
                    <p className="ant-upload-text">Profile Picture</p>
                  </Upload.Dragger>
                </Form.Item>
                </div>
              </Col>

              <Col span={18}>
                <Row>
                  <Col span={12}>
                    <Form.Item
                    label="First Name"
                    name="firstName"
                    hasFeedback
                    rules={[{ required: true }]}
                    initialValue={data.details && data.details.first_name ? data.details.first_name : ''}
                  >
                    <Input />
                  </Form.Item>
                  </Col>     
                  <Col span={12}>
                      <Form.Item
                      label="Last Name"
                      name="lastName"
                      hasFeedback
                      rules={[{ required: true }]}
                      initialValue={data.details && data.details.last_name ? data.details.last_name : ''}
                    >
                      <Input />
                    </Form.Item>
                  </Col>          
                </Row>
                <Row>
                  <Col span={12}>
                  <Form.Item
                    label="Email"
                    name="email"
                    hasFeedback
                    rules={[{ required: true, type: 'email' }]}
                    initialValue={data.email}
                  >
                    <Input disabled={data.id !== undefined && data.id > 0} />
                  </Form.Item>
                  </Col>
                  <Col span={12}>
                  <Form.Item
                    name="designation"
                    hasFeedback
                    label="Designation"
                    rules={[{ required: true }]}
                    initialValue={data.details && data.details.designation_id ? data.details.designation_id : 0}
                  >
                    <Select
                      placeholder="Please Select a Designation"
                      onChange={() => { }}
                      allowClear
                    >
                      {designations &&
                        designations.map((designation: Designation) => {
                          return (
                            <Select.Option
                              key={"emp-drop-" + designation.id.toString()}
                              value={designation.id}
                            >
                              {designation.name}
                            </Select.Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <Form.Item
                      label="Date of Birth"
                      hasFeedback
                      name="dob"
                      rules={[{ required: true }]}
                      initialValue={data.details && data.details.dob ? moment(data.details.dob) : null}
                    >
                      <DatePicker format="YYYY-MM-DD" picker="date" disabledDate={(current: any) => {
                        let customDate = moment().format("YYYY-MM-DD");
                        return current && current > moment(customDate, "YYYY-MM-DD");
                      }} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                        label="Gender"
                        hasFeedback
                        name="gender"
                        rules={[{ required: true }]}
                        initialValue={data.details && data.details.gender ? data.details.gender : null}
                    >
                      <Select
                          placeholder="Please Select Gender"
                          onChange={() => { }}
                          allowClear
                      >
                        <Select.Option value="Male" >
                          Male
                        </Select.Option>
                        <Select.Option value="Female" >
                          Female
                        </Select.Option>
                        <Select.Option value="Other" >
                          Other
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />
            <Row>
              <Col span={9} offset={6}>
              <Form.Item
                  label="Mobile No"
                  name="mobile"
                  hasFeedback
                  rules={[{ required: true, type: 'string', max: 12 }]}
                  initialValue={data.details && data.details.mobile ? data.details.mobile : ''}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                  label="Fixed Line"
                  name="fixedLine"
                  hasFeedback
                  rules={[{ type: 'string', max: 12 }]}
                  initialValue={data.details && data.details.fixedline ? data.details.fixedline : ''}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={9} offset={6}>

              </Col>
              <Col span={9}>

              </Col>
            </Row>
            <Row>
              <Col span={9} offset={6}>
                <Form.Item
                    label="Emergency Contact Name"
                    name="emergencyName"
                    hasFeedback
                    rules={[{  type: 'string' }]}
                    initialValue={data.details && data.details.emg_name ? data.details.emg_name : ''}
                  >
                    <Input />
                </Form.Item>
              </Col>
              <Col span={9}>
                <Form.Item
                    label="Emergency Phone No"
                    name="emergencyNo"
                    hasFeedback
                    rules={[{ type: 'string', max: 12 }]}
                    initialValue={data.details && data.details.emg_phone ? data.details.emg_phone : ''}
                  >
                    <Input />
                </Form.Item>
              </Col>
            </Row>

            <Row>
               <Col span={18} offset={6} className="fullWidthInner">
                  <Form.Item
                    label="Permanent Address"
                    name="address"
                    hasFeedback
                    rules={[{ required: true }]}
                    initialValue={data.details && data.details.address ? data.details.address : ''}
                  >
                    <Input.TextArea />
                  </Form.Item>
               </Col>
            </Row>
            <Row>
              <Col span={18} offset={6} className="fullWidthInner">
                <Form.Item>
                  <Checkbox onChange={onChangeOptionCurrentAddress} checked={optionCurrentAddress} >Permanent address is the current address</Checkbox>
                </Form.Item>
              </Col>
            </Row>
            {
              !optionCurrentAddress &&
              <Row>
                <Col span={18} offset={6} className="fullWidthInner">
                  <Form.Item
                      label="Current Address"
                      name="current_address"
                      hasFeedback
                      initialValue={data.details && data.details.current_address ? data.details.current_address : ''}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
            }


            <Row>
              <Col span={18} offset={6}>
                <Divider />
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                
                <Form.Item wrapperCol={{ offset: 16, span: 8 }}>
                <div className="btn-wrapper">
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  </div>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
};
