import {Button, Drawer, Image, Input, message, Popconfirm, Space, Spin, Switch, Table, Typography,} from "antd";
import React, {useEffect, useState} from "react";
import {DeleteOutlined, EditOutlined, FileImageOutlined, InfoOutlined, SearchOutlined} from "@ant-design/icons";
import "./style.css";
import {Announcement, AnnouncementFeedback, AnnouncementImage, AnnouncementUserFeedback, User} from "../../types/types";
import {useHistory} from "react-router-dom";
import {changeStatusAnnouncement, deleteAnnouncement, getAnnouncements} from "../../services/announcement";
import {AnnouncementForm, emptyUser} from "../../elements/announcementForm/AnnouncementForm";
import Modal from "antd/lib/modal/Modal";
import {FeedbackList} from "./components/FeedbackList";
import {FeedbackProgress} from "./components/FeedbackProgress";
import { userInfo } from "os";

interface FilterItem {
    setSelectedKeys: Function;
    selectedKeys: any[];
    confirm: Function;
    clearFilters: Function;
}

export interface AnnouncementResult {
    announcements: Announcement[];
    total: number;
    users: User[];
}

const emptyImage: any = {};

// announcement feedback user list
const emptyUserList: AnnouncementUserFeedback[] = [];

const emptyAnnouncement: Announcement = {
    id: 0,
    creator_id: 0,
    designation_id: 0,
    published_at: "",
    title: "",
    description: "",
    status: 0,
    images: [],
    key: 0,
    responses: [],
};

export const Announcements: Function = () => {
    let {push} = useHistory();
    let [searchText, setSearchText] = useState("");
    let [searchColumn, setSearchColumn] = useState("");
    let [announcements, setAnnouncements] = useState([emptyAnnouncement]);
    let [page, setPage] = useState(0);
    let [total, setTotoal] = useState(0);
    let [isLoading, setIsLoading] = useState(true);
    let [searchInput, setSearchInput] = useState({
        select: () => {
        },
    });
    let [isAnnouncementDrawerVisible, setAnnouncementDrawerVisible] =
        useState(false);
    let [isFeedbackDrawerVisible, setFeedbackDrawerVisible] =
        useState(false);
    let [modelImages, setModalImages] = useState([emptyImage]);
    let [imageMoalVisible, setImageModalVisiblity] = useState(false);

    // set selected announcement feedback
    let [modelUserList, setModelUserLit] = useState(emptyUserList);

    let [editingAnnouncement, setEditingAnnouncement] = useState(emptyAnnouncement);

    const storageUser = localStorage.getItem("user");
    const [loggedUser] = useState(
        localStorage.getItem("user") !== null
            ? JSON.parse(storageUser !== null ? storageUser : "")
            : emptyUser
    );
    const handleSearch = (
        selectedKeys: any[],
        confirm: Function,
        dataIndex: any
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };
    const handleReset = (clearFilters: Function) => {
        clearFilters();
        setSearchText(searchText);
    };
    const loadAnnouncements = () => {
        setIsLoading(true);
        getAnnouncements(page)
            .then((res: AnnouncementResult) => {
                res.announcements = res.announcements.map(
                    (announcement: Announcement) => {
                        announcement.key = "ann-" + announcement.id;
                        return announcement;
                    }
                );
                setAnnouncements(res.announcements);
                setTotoal(res.users.length);
                // set user list
                setModelUserLit(res.users);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                message.error("Failed to load Announcements. Redirecting to login ...");
                push("/login");
            });
    };
    const formatUserFeedbackList = (feedback: AnnouncementFeedback[]) => {
        const userList = modelUserList.map((u: AnnouncementUserFeedback) => {
            const currentUserFeedback = feedback.filter(f => f.user_id === u.id);
            u.feedbck = {};
            if (currentUserFeedback) {
                u.feedbck = currentUserFeedback[0];
            }
            return u;
        });
        setModelUserLit(userList);
    }

    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: (filter: FilterItem) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={filter.selectedKeys[0]}
                    onChange={(e) =>
                        filter.setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
                    }
                    style={{marginBottom: 8, display: "block"}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
                        }
                        icon={<SearchOutlined/>}
                        size="large"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(filter.clearFilters)}
                        size="large"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
        onFilter: (value: any, record: any) => {
            if (dataIndex === 'author') {
                const filterName = `${record['creator']?.details?.first_name} ${record['creator']?.details?.last_name}`
                return filterName ? filterName.toLowerCase().includes(value.toLowerCase()) : '';
            }
            return record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "";
        }
        ,
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => {
                    if (searchInput && searchInput.select) {
                        searchInput.select();
                    }
                }, 100);
            }
        },
        render: (text: any) =>
            searchColumn === dataIndex ? (
                <Typography.Text>{text}</Typography.Text>
            ) : (
                <Typography.Text>{text}</Typography.Text>
            ),
    });
    const columns: any[] = [
        {
            title: "Author",
            dataIndex: "creator",
            key: "creator",
            // onFilter: (value: string, creator:User) => creator.details?.first_name.indexOf(value) === 0,
            ...getColumnSearchProps("author"),
            render: (creator: User) => creator?.details?.first_name
        },
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            width: "20%",
            ...getColumnSearchProps("title"),
        },
        {
            title: "description",
            dataIndex: "description",
            key: "description",
            width: "30%",
            ...getColumnSearchProps("description"),
        },
        // {
        //     title: "Resources",
        //     dataIndex: "images",
        //     key: "images",
        //     render: (data: AnnouncementImage[]) => {
        //         return (
        //             <Space>
        //                 <Button
        //                     key={"pop-up-button" + Math.random()}
        //                     icon={<FileImageOutlined/>}
        //                     onClick={() => {
        //                         if (data.length > 0) {
        //                             setModalImages(data);
        //                         }
        //                         setImageModalVisiblity(true);
        //                     }}
        //                 ></Button>
        //             </Space>
        //         );
        //     },
        // },
        {
            title: "Date and Time",
            dataIndex: "published_at",
            key: "published_at",
            sorter: (a: Announcement, b: Announcement) => new Date(a.published_at).getTime() - new Date(b.published_at).getTime(),
            sortDirections: ['descend', 'ascend']
        },
        {
            title: "Feedback",
            dataIndex: "responses",
            key: "responses",
            width: "15%",
            render: (data: any[], ann: Announcement) => {
                // return <b key={"response-" + Math.random()}>{data.length}</b>;
                return (
                    <>
                        <FeedbackProgress feedback={ann.responses} total={total || 1}/>
                        <div className="view-info">
                            <Button
                                icon={<InfoOutlined/>}
                                onClick={() => {
                                    if (data) {
                                        formatUserFeedbackList(data);
                                    }
                                    setFeedbackDrawerVisible(true);
                                }}
                            />
                        </div>
                    </>
                )
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            sorter: (a: Announcement, b: Announcement) => a.status - b.status,
            sortDirections: ['descend', 'ascend'],
            render: (status: number, ann: Announcement) => {
                return (
                    loggedUser && Boolean(loggedUser.admin) ? <Switch
                        key={"switch-" + Math.random()}
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                        onChange={() => {
                            changeStatusAnnouncement(ann.id, status > 0 ? 0 : 1).then((res: any) => {
                                if (res.error) {
                                    message.error(res.error);
                                }
                                setAnnouncements(announcements.map((cann: Announcement) => {
                                    if (cann.id === ann.id) {
                                        cann.status = cann.status > 0 ? 0 : 1;
                                    }
                                    return cann;
                                }));
                                message.success("Announcement is " + (status > 0 ? 'disabled!' : 'enabled!'));
                            });
                        }}
                        defaultChecked={status > 0}
                    /> : (status > 0 ? 'Active' : 'Disabled')
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "actions",
            render: (id: number,ann:Announcement) => {
                return (
                    <Space>
                        {loggedUser && Boolean(loggedUser.admin) && (
                            <Button
                                key={"edit-button-" + id.toString()}
                                icon={<EditOutlined/>}
                                onClick={() => {
                                    setEditingAnnouncement(ann);
                                    setAnnouncementDrawerVisible(true);

                                }}
                            />
                        )}
                        {
                            loggedUser && Boolean(loggedUser.admin) ? <Space key={Math.random()}>
                                <Popconfirm
                                    title="Are you sure to delete this announcement?"
                                    onConfirm={() => {
                                        deleteAnnouncement(id).then((res: any) => {
                                            if (res.error) {
                                                message.error(res.error);
                                                return;
                                            }
                                            message.success("Announcement deleted!");
                                            loadAnnouncements();
                                        });
                                    }}
                                    onCancel={() => {
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button
                                        key={"delete-button-" + Math.random()}
                                        icon={<DeleteOutlined/>}
                                    />
                                </Popconfirm>
                            </Space> : ''
                        }

                    </Space>

                );
            },
        },
    ];
    useEffect(() => {
        loadAnnouncements();
    }, [page]);
    if (isLoading) {
        return <Spin size="large" className="spin"/>;
    }
    return (
        <>
            <div className="top-bar">
                <h2>Announcements</h2>
                {loggedUser && Boolean(loggedUser.admin) && <Button
                    type="primary"
                    className="add-announcement"
                    onClick={() => {
                        setEditingAnnouncement(emptyAnnouncement);
                        setAnnouncementDrawerVisible(true);
                    }}
                >
                    Add new Announcement
                </Button>}
            </div>
            <Table
                columns={loggedUser && Boolean(loggedUser.admin) ? columns : columns.filter(col => col.dataIndex !== 'id')}
                dataSource={announcements}
                pagination={{
                    total: total,
                    current: page + 1,
                    onChange: (page: number) => {
                        setPage(page - 1);
                    },
                }}
            />
            <Drawer
                placement="right"
                closable={false}
                className="addAnnouncement"
                width={window.innerWidth * 0.8}
                onClose={() => {
                    setAnnouncementDrawerVisible(false);
                }}
                visible={isAnnouncementDrawerVisible}
            >
                {
                    isAnnouncementDrawerVisible &&  <AnnouncementForm
                        onDone={() => {
                            loadAnnouncements();
                            setAnnouncementDrawerVisible(false);
                        }}
                        data={editingAnnouncement}
                    />
                }

            </Drawer>
            <Modal
                title="Announcement Images"
                visible={imageMoalVisible}
                onCancel={() => {
                    setImageModalVisiblity(false);
                }}
                footer={[
                    <Button
                        key="back"
                        onClick={() => {
                            setImageModalVisiblity(false);
                        }}
                    >
                        OK
                    </Button>,
                ]}
            >
                {modelImages &&
                modelImages.map((image: any) => {
                    return (
                        <Image
                            style={{
                                padding: 10,
                            }}
                            key={"img-" + image.id}
                            width={200}
                            src={image.url}
                        />
                    );
                })}
            </Modal>
            <Drawer
                placement="right"
                closable={true}
                className="feedback-drawer"
                width={600}
                onClose={() => {
                    setFeedbackDrawerVisible(false);
                }}
                visible={isFeedbackDrawerVisible}
            >
                <FeedbackList userList={modelUserList}/>
            </Drawer>
        </>
    );
};
