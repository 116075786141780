import {
  Button,
  Input,
  message,
  Space,
  Spin,
  Table,
  Typography,
  Switch,
  Drawer,
  Popconfirm,
} from "antd";
import React, {useEffect, useState} from "react";
import {
    SearchOutlined,
    DeleteOutlined, EditOutlined,
} from "@ant-design/icons";
import "./style.css";
import {Event, User} from "../../types/types";
import {useHistory} from "react-router-dom";
import {changeStatusEvent, deleteEvent, getEvents} from "../../services/event";
import {EventForm} from "../../elements/eventForm/EventForm";

interface FilterItem {
    setSelectedKeys: Function;
    selectedKeys: any[];
    confirm: Function;
    clearFilters: Function;
}

const emptyUser: User = {
    name: "",
    email: "",
    phone: "",
    admin: 0
};

export interface EventResult {
    events: Event[];
    total: number;
}

const emptyEvent: Event = {
    id: 0,
    user_id: 0,
    event_type_id: 0,
    description: "",
    name: "",
    status: 0,
    key: 0,
    organizer: emptyUser,
    type: {},
    published_at: '',
    end_at:'',
    users:[]
};

export const Events: Function = () => {
    let {push} = useHistory();
    let [searchText, setSearchText] = useState("");
    let [searchColumn, setSearchColumn] = useState("");
    let [events, setEvents] = useState([emptyEvent]);
    let [page, setPage] = useState(0);
    let [total, setTotoal] = useState(0);
    let [isLoading, setIsLoading] = useState(true);
    let [searchInput, setSearchInput] = useState({
        select: () => {
        },
    });
    let [isEventDrawerVisible, setEventDrawerVisible] =
        useState(false);
    const storageUser = localStorage.getItem("user");
    const [loggedUser] = useState(
        localStorage.getItem("user") !== null
            ? JSON.parse(storageUser !== null ? storageUser : "")
            : emptyUser
    );
    let [editingEvent, setEditingEvent] = useState(emptyEvent);
    const handleSearch = (
        selectedKeys: any[],
        confirm: Function,
        dataIndex: any
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchColumn(dataIndex);
    };
    const handleReset = (clearFilters: Function) => {
        clearFilters();
        setSearchText(searchText);
    };
    const loadEvents = () => {
        setIsLoading(true);
        getEvents(page)
            .then((res: EventResult) => {
                res.events = res.events.map(
                    (event: Event) => {
                        event.key = "evt-" + event.id;
                        return event;
                    }
                );
                setEvents(res.events);
                setTotoal(res.total);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
                message.error("Failed to load Events. Redirecting to login ...");
                push("/login");
            });
    };
    const getColumnSearchProps = (dataIndex: any) => ({
        filterDropdown: (filter: FilterItem) => (
            <div style={{padding: 8}}>
                <Input
                    placeholder={`Search ${dataIndex}`}
                    value={filter.selectedKeys[0]}
                    onChange={(e) =>
                        filter.setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
                    }
                    style={{marginBottom: 8, display: "block"}}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
                        }
                        icon={<SearchOutlined/>}
                        size="large"
                        style={{width: 90}}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => handleReset(filter.clearFilters)}
                        size="large"
                        style={{width: 90}}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: any) => (
            <SearchOutlined style={{color: filtered ? "#1890ff" : undefined}}/>
        ),
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex]
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => {
                    if (searchInput && searchInput.select) {
                        searchInput.select();
                    }
                }, 100);
            }
        },
        render: (text: any) =>
            searchColumn === dataIndex ? (
                <Typography.Text key={"text-" + Math.random()}>{text}</Typography.Text>
            ) : (
                <Typography.Text key={"text-" + Math.random()}>{text}</Typography.Text>
            ),
    });
    const columns: any = [
        {
            title: "Host Name",
            dataIndex: "organizer",
            key: "organizer",
            ...getColumnSearchProps("organizer.name"),
            render: (organizer: any, cevt: Event) => {
                return <b>{organizer && organizer.details ? organizer.details.first_name + ' '+  organizer.details.last_name: 'Loading ...'}</b>;
            }
        },
        {
            title: "Title",
            dataIndex: "name",
            key: "name",
            ...getColumnSearchProps("name"),
        },
        {
            title: "Description",
            dataIndex: "description",
            key: "description",
            width: "30%",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
            render: (type: any, cevt: Event) => {
                return <b>{type.name}</b>;
            }
        },
        {
            title: "Date/Time",
            dataIndex: "published_at",
            key: "published_at",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status: number, cevt: Event) => {
                return (
                    <Switch
                        key={"switch-" + Math.random()}
                        checkedChildren="Enabled"
                        unCheckedChildren="Disabled"
                        onChange={() => {
                            changeStatusEvent(cevt.id, status > 0 ? 0 : 1).then((res: any) => {
                                if (res.error) {
                                    message.error(res.error);
                                }
                                setEvents(events.map((evt: Event) => {
                                    if (cevt.id === evt.id) {
                                        evt.status = cevt.status > 0 ? 0 : 1;
                                    }
                                    return evt;
                                }));
                                message.success("Event is " + (status > 0 ? 'disabled!' : 'enabled!'));
                            });
                        }}
                        defaultChecked={status > 0}
                        disabled={!(loggedUser && Boolean(loggedUser.admin)) && loggedUser.id !== cevt.user_id}
                    />
                );
            },
        },
        {
            title: "Actions",
            dataIndex: "id",
            key: "actions",
            render: (id: number, event: Event) => {
                return (
                    <Space>
                        {loggedUser && Boolean(loggedUser.admin) && (
                            <Button
                                key={"edit-button-" + id.toString()}
                                icon={<EditOutlined/>}
                                onClick={() => {

                                    setEditingEvent(event);
                                    setEventDrawerVisible(true);
                                }}
                            />
                        )}
                        {
                            ((loggedUser && Boolean(loggedUser.admin)) || loggedUser.id === event.user_id) ?
                                <Space key={Math.random()}>
                                    <Popconfirm
                                        title="Are you sure to delete this event?"
                                        onConfirm={() => {
                                            deleteEvent(id).then((res: any) => {
                                                if (res.error) {
                                                    message.error(res.error);
                                                    return;
                                                }
                                                message.success("Event deleted!");
                                                loadEvents();
                                            });
                                        }}
                                        onCancel={() => {
                                        }}
                                        okText="Yes"
                                        cancelText="No"
                                    >
                                        <Button
                                            key={"delete-button-" + Math.random()}
                                            icon={<DeleteOutlined/>}
                                        ></Button>
                                    </Popconfirm>
                                </Space> : ''
                        }
                    </Space>

                );
            },
        },
    ];
    useEffect(() => {
        loadEvents();
    }, [page]);
    if (isLoading) {
        return <Spin size="large" className="spin"/>;
    }
    return (
        <>
            <Button
                type="primary"
                className="add-event"
                onClick={() => {

                    setEditingEvent(emptyEvent);
                    setEventDrawerVisible(true);
                }}
            >
                Add new Event
            </Button>
            <Table
                columns={columns}
                dataSource={events}
                pagination={{
                    total: total,
                    current: page + 1,
                    onChange: (page: number) => {
                        setPage(page - 1);
                    },
                }}
            />
            <Drawer
                placement="right"
                className="addEvent"
                closable={false}
                width={window.innerWidth * 0.8}
                onClose={() => {
                   // setEditingEvent(emptyEvent);
                    setEventDrawerVisible(false);
                }}
                visible={isEventDrawerVisible}
            >
                {isEventDrawerVisible && <EventForm
                    onDone={() => {
                        loadEvents();
                        setEventDrawerVisible(false);
                    }}
                    data={editingEvent}
                />}
            </Drawer>
        </>
    );
};
