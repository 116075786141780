import { Event } from "../types/types";

export const getEvents: Function = (page: number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/events/' + page.toString(), {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json'
        })
    }).then(res=>res.json());
}

export const getEventTypes: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/event-types', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}

export const createEvent: Function = (event: Event) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/event', {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
      credentials: "include",
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        referrerPolicy: 'no-referrer', 
    
        body: JSON.stringify(event)
    }).then(res=>res.json());
}
export const updateEvent: Function = (event: Event,id:number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/event/'+id.toString(), {
        method: 'PUT',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json'
        },
        referrerPolicy: 'no-referrer',

        body: JSON.stringify(event)
    }).then(res=>res.json());
}

export const deleteEvent: Function = (id: number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/event/'+id.toString(), {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
      credentials: "include",
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        referrerPolicy: 'no-referrer', 
    
        body: JSON.stringify({})
    }).then(res=>res.json());
}

export const changeStatusEvent: Function = (id: number, status: number) => {
    return fetch(process.env.REACT_APP_API_URL + '/api/event/'+id.toString()+ '/' + status.toString(), {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
      credentials: "include",
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        referrerPolicy: 'no-referrer', 
    
        body: JSON.stringify({})
    }).then(res=>res.json());
}

export const getLatestEvents: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/latest-events', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}
