export interface User{
    id?: number;
    name: string;
    email: string;
    phone: string;
    key?: number;
    avatar?: string;
    details?: any;
    admin: number;
    deleted_at?: string|null;
}
export interface AnnouncementImage {
    id?: number;
    announcement_id: number;
    path: string;
    name: string;
    url?: string;
}
// Announcement feedback
export interface AnnouncementFeedback{
    id?:number;
    name?:string;
    email?:string;
    status?:number;
    seen?:number;
    user_id?:number;
    user_details?:{
        name?:string;
    }
}
export interface AnnouncementUserFeedback extends User{
    feedbck?:AnnouncementFeedback;
}
export interface Announcement {
    id?: number;
    creator_id: number;
    designation_id: number;
    published_at: string;
    title: string;
    description: string;
    status: number;
    images?: AnnouncementImage[];
    key: any;
    responses: AnnouncementFeedback[];
    creator?:User;
}
export interface Designation {
    id: number;
    name: string;
}
export interface EventType {
    id: number;
    name: string;
}
export interface EventParticipant extends User{
    user_id:number;
    event_id:number;
}
export interface Event {
    id?: number;
    user_id: number;
    event_type_id: number;
    organizer: User;
    type: any;
    name: string;
    status: number,
    description: string;
    key: any;
    published_at: string;
    end_at?:string;
    users?:EventParticipant[];
    user_all?:boolean;
}

export interface Employee {
    id?: number;
    image: string;
    firstName: string;
    lastName: string;
    email: string;
    designation: number;
    dob: string;
    mobile: string;
    fixedLine: string;
    emergencyName: string;
    emergencyNo: string;
    address: string;
}

export enum FeedbackStatus{
    seen=1,
    acknowledge=2,
    notSeen=0
}
