import React from "react";
import {
  Button,
  Drawer,
  Input,
  message,
  Popconfirm,
  Space,
  Spin,
  Table,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import {
  deleteEmployee,
  getEmployees,
  makeAdmin,
  me,
  restoreEmployee,
} from "../../services/auth";
import {
  SearchOutlined,
  DeleteOutlined,
  EditOutlined,
  UndoOutlined
} from "@ant-design/icons";
import "./style.css";
import { User } from "../../types/types";
import { useHistory } from "react-router-dom";
import { EmployeeForm } from "../../elements/employeeForm/EmployeeForm";

interface FilterItem {
  setSelectedKeys: Function;
  selectedKeys: any[];
  confirm: Function;
  clearFilters: Function;
}

export interface EmployeeResult {
  employees: User[];
  total: number;
}

const emptyUser: User = {
  id: 0,
  name: "",
  email: "",
  phone: "",
  key: 0,
  admin: 0,
};



export const Employees: Function = () => {
  let { push } = useHistory();
  let [searchText, setSearchText] = useState("");
  let [searchColumn, setSearchColumn] = useState("");
  let [employees, setEmployees] = useState([emptyUser]);
  let [isLoading, setIsloading] = useState(true);
  let [page] = useState(0);
  let [registerVisible, setRegisterVisible] = useState(false);
  let [searchInput, setSearchInput] = useState({
    select: () => {},
  });
  let [editingUser, setEditingUser] = useState(emptyUser);
  let storageUser = localStorage.getItem("user");
  let [loggedUser] = useState(
    localStorage.getItem("user") !== null
      ? JSON.parse(storageUser !== null ? storageUser : "")
      : emptyUser
  );
  const handleSearch = (
    selectedKeys: any[],
    confirm: Function,
    dataIndex: any
  ) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchColumn(dataIndex);
  };
  const handleReset = (clearFilters: Function) => {
    clearFilters();
    setSearchText('');
  };
  const loadEmployees = () => {
    getEmployees(page, 1).then((response: EmployeeResult) => {
      const employees = response.employees.map(
        (employee,index) => {
          employee.key = index;
          employee.name = employee?.details?.first_name + ' ' + employee?.details?.last_name
          return employee;
        }
      )
      setEmployees(employees);
    });
  };
  useEffect(() => {

  },[]);
  const getColumnSearchProps = (dataIndex: any) => ({
    filterDropdown: (filter: FilterItem) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={filter.selectedKeys[0]}
          onChange={(e) =>
            filter.setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() =>
              handleSearch(filter.selectedKeys, filter.confirm, dataIndex)
            }
            icon={<SearchOutlined />}
            size="large"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => handleReset(filter.clearFilters)}
            size="large"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: any) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput && searchInput.select) {
            searchInput.select();
          }
        }, 100);
      }
    },
    render: (text: any) =>
      searchColumn === dataIndex ? (
        <Typography.Text>{text}</Typography.Text>
      ) : (
        <Typography.Text>{text}</Typography.Text>
      ),
  });
  const [user, setUser] = useState(emptyUser);
  const columns: any[] = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
      ...getColumnSearchProps("name"),
      sorter: (a:User, b:User) => a.name.toLowerCase().localeCompare( b.name.toLowerCase()),
      defaultSortOrder: 'ascend',
      render: (data: User, user: User) => { 
        return <span
        className="empName"
        >{ user.name?user.name:'_'}</span>;
      }
    },
    {
      title: "email",
      dataIndex: "email",
      key: "email",
      width: "20%",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Phone Number",
      dataIndex: "mobile",
      key: "mobile",
      render: (data: number, user: User) => { 
        return <p>{ user.details?user.details.mobile:'_'}</p>;
      }
    },
    {
      title: "Actions",
      dataIndex: "id",
      key: "actions",
      render: (data: number, user: User) => {
        return (
          <Space>
            {loggedUser && Boolean(loggedUser.admin) && user.deleted_at === null && (
              <Popconfirm
                title="Are you sure to delete this user?"
                onConfirm={() => {
                  deleteEmployee(data).then(() => {
                    loadEmployees();
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key={"delete-button-" + data.toString()}
                  icon={<DeleteOutlined />}
                ></Button>
              </Popconfirm>
            )}
            {loggedUser && Boolean(loggedUser.admin) && user.deleted_at !== null && (
              <Popconfirm
                title="Are you sure to restore this user?"
                onConfirm={() => {
                  restoreEmployee(data).then(() => {
                    loadEmployees();
                  });
                }}
                onCancel={() => {}}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  key={"delete-button-" + data.toString()}
                  icon={<UndoOutlined />}
                ></Button>
              </Popconfirm>
            )}
            {loggedUser && Boolean(loggedUser.admin) && (
              <Button
                key={"edit-button-" + data.toString()}
                icon={<EditOutlined />}
                onClick={() => {
                  setEditingUser(user);
                  setRegisterVisible(true);
                }}
              ></Button>
            )}
            {loggedUser && Boolean(loggedUser.admin) && !Boolean(user.admin)  && user.deleted_at === null && (
              <Button
                key={"admin-button-" + data.toString()}
                onClick={() => {
                  makeAdmin(data, "1").then(() => {
                    loadEmployees();
                  });
                }}
              >
                Make Admin
              </Button>
            )}
            {loggedUser && Boolean(loggedUser.admin) && Boolean(user.admin)  && user.deleted_at === null && (
              <Button
                key={"admin-button-" + data.toString()}
                onClick={() => {
                  makeAdmin(data, "0").then(() => {
                    loadEmployees();
                  });
                }}
              >
                Undo Admin
              </Button>
            )}
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    me()
      .then((res: User) => {
        setIsloading(false);
        setUser(res);
      })
      .catch(() => {
        setIsloading(false);
        message.error("Failed to load user details. Redirecting to login ...");
        push("/login");
      });
  }, []);
  useEffect(() => {
    loadEmployees();
  }, [page]);

  if (isLoading) {
    return <Spin size="large" className="spin" />;
  }
  return (
    <>
    <div className="top-bar">
    <h2>Employees</h2>
      {loggedUser && Boolean(loggedUser.admin) && <Button
        type="primary"
        className="add-employee"
        onClick={() => {
          setEditingUser(emptyUser);
          setRegisterVisible(true);
        }}
      >
        Add new Employee
      </Button>}
    </div>

      <Table className={(loggedUser && Boolean(loggedUser.admin))?"":"employeeTable"} columns={loggedUser && Boolean(loggedUser.admin) ? columns : columns.filter(col => col.dataIndex !== 'id')} dataSource={employees} />
      <Drawer
        title={(editingUser.id !== undefined && editingUser.id > 0 ? "Edit ":"Create an") + " Employee"}
        placement="right"
        className={(editingUser.id !== undefined && editingUser.id > 0 ? "editEmp ":"addEmp")}
        closable={false}
        width={window.innerWidth * 0.8}
        onClose={() => {
          setRegisterVisible(false);
        }}
        visible={registerVisible}
      >
        {registerVisible && <EmployeeForm
          onDone={() => {
            loadEmployees();
            setRegisterVisible(false);
          }}
          data={editingUser}
        />}
      </Drawer>
    </>
  );
};
