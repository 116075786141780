import {
    Col,
    PageHeader,
    Row,
    Form,
    Input,
    Button,
    Select,
    message,
    DatePicker,
    Divider,
    Upload,
    Spin,
} from "antd";
import "./style.css";
import {PlusOutlined} from "@ant-design/icons";
import {useEffect, useState} from "react";
import {getAllEmployees} from "../../services/auth";
import {getDesignations} from "../../services/designation";
import {Announcement, Designation, User} from "../../types/types";
import {createAnnouncement, updateAnnouncement} from "../../services/announcement";
import moment from "moment";

export const emptyUser: User = {
    name: "",
    email: "",
    phone: "",
    details: {},
    admin: 0
};
export const emptyDesignation: Designation = {
    id: 0,
    name: "",
};

export interface AnnouncementFormProps {
    onDone: Function;
    data:Announcement
}

const emptyImage: any = null;
export const AnnouncementForm = ({onDone,data}: AnnouncementFormProps) => {

    let [employees, setEmployees] = useState([emptyUser]);
    let [designations, setDesignations] = useState([emptyDesignation]);
    let [modelDesignation, setDesignation] = useState('');
    let [uploadImages, setUploadImages] = useState([emptyImage]);
    let [isLoading, setIsloading] = useState(false);
    useEffect(() => {

        getAllEmployees().then((res: User[] | any) => {
            if (res.error) {
                message.error(res.error);
                return;
            }
            setEmployees(res.sort((a: User, b: User) => a.details?.first_name.toLowerCase().localeCompare(b.details?.first_name.toLowerCase())));
        });
        // getDesignations().then((designationList: Designation[]) => {
        //     setDesignations(designationList);
        // });

        if(data && data.creator){
            setDesignation(data.creator.details.designation.id);
        }
    }, []);

    const onChangeEmployee = (value: any, options: any) => {
        console.log(options.designation)
        if (options && options.designation) {
            setDesignation(`${options.designation}`);
        }
    }
    if (isLoading) {
        return <Spin className="spin" size="large"/>;
    }
    return (
        <>
            <Row>
                <Col span={24}>
                    <PageHeader
                        onBack={() => {
                            onDone();
                        }}
                        title={(data.id && data.id > 0 ? ('Edit Announcement') :"Add Announcement")}
                        subTitle={(data.id && data.id > 0 ? "You can edit the announcement" : "Please fill out the information below")}

                    />
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        className="form-announcement"
                        layout={"vertical"}
                        labelCol={{span: 24}}
                        wrapperCol={{span: 24}}
                        initialValues={{ remember: true }}
                        onFinish={(value: any) => {
                          const  announcementObj = {
                                creator_id: value.publisher,
                                designation_id: modelDesignation,
                                published_at: value.published_at.format("YYYY-MM-DD HH:mm:ss"),
                                title: value.title,
                                description: value.description,
                                images: uploadImages
                                    .map((image: any) => {
                                        return image && image.response ? image.response.id : 0;
                                    })
                                    .filter((id: number) => {
                                        return id > 0;
                                    }),
                            }


                            setIsloading(true);
                            if (data.id && data.id > 0) {
                                updateAnnouncement(announcementObj, data.id).then((response: any) => {
                                    if (response.error) {
                                        message.error(response.error);
                                        return;
                                    }
                                    setIsloading(false);
                                    message.success("Announcement Saved!");
                                    onDone();
                                });
                            }else {
                                createAnnouncement(announcementObj).then((response: any) => {
                                    if (response.error) {
                                        message.error(response.error);
                                        return;
                                    }
                                    setIsloading(false);
                                    message.success("Announcement Saved!");
                                    onDone();
                                });
                            }

                        }}
                        onFinishFailed={() => {
                        }}
                    >

                        <Row>
                            <Col span={8}>
                                <Form.Item
                                    name="publisher"
                                    hasFeedback
                                    label="Publisher"
                                    rules={[{required: true}]}
                                    initialValue={data && data.creator_id? data.creator_id :null}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Please Select a Publisher"
                                        onChange={(value, options) => onChangeEmployee(value, options)}
                                        optionFilterProp="children"
                                        
                                    >
                                        {employees &&
                                        employees.map((employee: User) => {
                                            return (
                                                <Select.Option
                                                    key={"emp-drop-" + employee.id?.toString()}
                                                    value={employee.id ? employee.id : 1}
                                                    designation={employee?.details?.designation?.id}
                                                >
                                                    {employee.details ? `${employee.details.first_name} ${employee.details.last_name}` : 'admin'}
                                                </Select.Option>
                                            );
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            {/*<Col span={8}>*/}
                            {/*    <Form.Item*/}
                            {/*        name="designation"*/}
                            {/*        hasFeedback*/}
                            {/*        label="Designation"*/}
                            {/*        rules={[{required: true}]}*/}


                            {/*    >*/}
                            {/*        <Select*/}
                            {/*            placeholder="Please Select a Designation"*/}
                            {/*            // onChange={() => {*/}
                            {/*            // }}*/}

                            {/*            value={modelDesignation}*/}
                            {/*        >*/}
                            {/*            {designations &&*/}
                            {/*            designations.map((designation: Designation) => {*/}
                            {/*                return (*/}
                            {/*                    <Select.Option*/}
                            {/*                        key={"emp-drop-" + designation.id.toString()}*/}
                            {/*                        value={designation.id}*/}
                            {/*                    >*/}
                            {/*                        {designation.name}*/}
                            {/*                    </Select.Option>*/}
                            {/*                );*/}
                            {/*            })}*/}
                            {/*        </Select>*/}
                            {/*    </Form.Item>*/}
                            {/*</Col>*/}

                            <Col span={8}>
                                <Form.Item
                                    label="Publish Date & TIme"
                                    hasFeedback
                                    name="published_at"
                                    rules={[{required: true}]}
                                    initialValue={data && data.published_at ? moment(data.published_at) :null }
                                >
                                    <DatePicker showTime format="YYYY-MM-DD HH:mm:ss"/>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span="24">
                                <h3>Announcement Details</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={16}>
                                <Form.Item
                                    label="Title"
                                    name="title"
                                    hasFeedback
                                    rules={[{required: true}]}
                                    initialValue={data ? data.title : null}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Description"
                                    name="description"
                                    hasFeedback
                                    rules={[{required: true}]}
                                    initialValue={data ? data.description : null}
                                >
                                    <Input.TextArea/>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item label="&nbsp;">
                                    <Form.Item
                                        name="dragger"
                                        valuePropName="fileList"
                                        getValueFromEvent={({fileList}) => {
                                            setUploadImages(fileList);
                                        }}
                                        noStyle
                                        initialValue={data && data.images ? data.images : null}
                                    >
                                        <Upload.Dragger
                                            name="files"
                                            listType="picture-card"
                                            action={
                                                process.env.REACT_APP_API_URL +
                                                "/api/announcement-image"
                                            }

                                        >
                                            <p className="ant-upload-drag-icon">
                                                <PlusOutlined/>
                                            </p>
                                            <p className="ant-upload-text">Add Pictures</p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                </Form.Item>


                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Divider/>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item wrapperCol={{offset: 16, span: 8}}>
                                    <div className="btn-wrapper">
                                        <Button type="primary" htmlType="submit">
                                            Save
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
