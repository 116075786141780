import { Col, List, Row, Spin, Statistic, Typography } from "antd";
import {
  TeamOutlined,
  SoundOutlined,
  CarryOutOutlined
} from "@ant-design/icons";
import "./style.css";
import Avatar from "antd/lib/avatar/avatar";
import { useEffect, useState } from "react";
import { getCounts, getLatestEmployees } from "../../services/auth";
import { Announcement, Event, User } from "../../types/types";
import { getLatestAnnouncements } from "../../services/announcement";
import { getLatestEvents } from "../../services/event";

const emptyUser: User = {
  name: '',
  email: '',
  phone: '',
  admin:0
}
const emptyAnn: any = {}
const emptyEvent: any = {
};

export const Summery: Function = () => {
  let [employees, setEmployees] = useState([emptyUser]);
  let [announcements, setAnnouncements] = useState([emptyAnn]);
  let [events, setEvents] = useState([emptyEvent]);
  const [counts, setCounts] = useState({
    user: 0,
    announcement: 0,
    events: 0,

  });
  let [isLoading, setIsloading] = useState(true);
  useEffect(() => {
    getLatestEmployees().then((response: User[]) => {
      setIsloading(false);
      setEmployees(response);
    });
    getLatestAnnouncements().then((response: Announcement[]) => {
      setIsloading(false);
      setAnnouncements(response);
    });
    getCounts().then((response: any) => {
      setIsloading(false);
      setCounts(response);
    });
    getLatestEvents().then((response: Event[]) => {
      setIsloading(false);
      setEvents(response);
    });
  }, []);
  if (isLoading) {
    return <Spin size="large" className="spin" />;
  }
  return (
    <>
    <div className="summary-view">
    <Row>
      <Col span={8}>
        <div className="col-inner">
        <Row>
          <Col span={23}   offset={1}>
            <Statistic className="ant-title emp" title="SCIT Family" value={counts.user.toString()} prefix={<TeamOutlined />} suffix="People">
            </Statistic>
          </Col>
        </Row>
        <Row>
          <Col span={23}   offset={1}>
            <List
              dataSource={employees}
              renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      <Avatar src={item.details && item.details.profile_image?item.details.profile_image: `${process.env.REACT_APP_API_URL}/storage/profile/default.jpg`} />
                    }
                    title={<p>{item.details !== null && item.details !== undefined && item.details && item.details.first_name?item.details.first_name + " " + item.details.last_name:item.name}</p>}
                    description={item.email}
                  />
                </List.Item>
              )}
            />
            <div className="viewMore"><a href="/dashboard/employees">View More &raquo;</a></div>
          </Col>
        </Row>
        </div>
      </Col>
      <Col span={8}>
      <div className="col-inner">
      <Row>
          <Col span={23} offset={1}>
            <Statistic className="ant-title announcements" title="Announcements" value={counts.announcement} prefix={<SoundOutlined />} suffix={'Active Post'+ (counts.announcement>1?'s':'')} />
          </Col>
        </Row>
        <Row>
          <Col span={23}  offset={1} className="annoucement-data">
            <List
              dataSource={announcements}
              renderItem={item => (
                <List.Item key={item.id}>
                  <List.Item.Meta
                    avatar={
                      item.images && item.images.length > 0 ? <Avatar shape="square" src={ item.images[0].url} /> : <Avatar shape="square" icon={<SoundOutlined />} />
                    }
                    title={<Typography.Text>{item.title}</Typography.Text>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
             <div className="viewMore"><a href="/dashboard/announcements">View More &raquo;</a></div>
          </Col>
        </Row>
        </div>
      </Col>
      <Col span={8}>
      <div className="col-inner">
      <Row>
          <Col span={23} offset={1}>
            <Statistic className="ant-title events" title="Events" value={counts.events} prefix={<CarryOutOutlined />} suffix={' Recent' + (counts.events>1?'s':'')}/>
          </Col>
        </Row>
        <Row>
          <Col span={23} offset={1}>
            <List
              dataSource={events}
              renderItem={item => (
                <List.Item key={item.id?item.id:''}>
                  <List.Item.Meta
                    title={<Typography.Text>{item.name}</Typography.Text>}
                    description={item.description}
                  />
                </List.Item>
              )}
            />
             <div className="viewMore"><a href="/dashboard/events">View More &raquo;</a></div>
          </Col>
        </Row>
        </div>
      </Col>
    </Row>
    </div>
    </>
  );
};
