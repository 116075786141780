export const getDesignations: Function = () => {
    return fetch(process.env.REACT_APP_API_URL + '/api/designation-list', {
        mode: "cors",
      cache: "no-cache",
      credentials: "include",
        headers: new Headers({
            'Authorization': 'Bearer ' + localStorage.getItem("token"),
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        })
    }).then(res=>res.json());
}